import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../src/assets/logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_ctx.$route.name != 'Politica de privacidad' && _ctx.$route.name != 'Aviso legal')
        ? (_openBlock(), _createBlock(_component_v_app_bar, {
            key: 0,
            dense: "",
            flat: "",
            color: "white"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: "/" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_img, {
                        alt: "Vuetify Logo",
                        class: "shrink mr-2 logo",
                        contain: "",
                        src: _imports_0,
                        transition: "scale-transition",
                        "max-width": "160"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_spacer),
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, { text: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Inicio ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, { to: "/quienes-somos" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, { text: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Quienes Somos ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_menu, { "open-on-hover": "" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ text: "" }, props, _toHandlers(props)), {
                    default: _withCtx(() => [
                      _createTextVNode(" Servicios ")
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items_services, (item, index) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          to: item.url,
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, { class: "full-width" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, { class: "full-width" })
        ]),
        _: 1
      }),
      (_ctx.$route.name != 'Politica de privacidad' && _ctx.$route.name != 'Aviso legal')
        ? (_openBlock(), _createBlock(_component_FooterComponent, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}