
<template>
    <ServicioLimpieza/>  
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import ServicioLimpieza from '../components/ServicioLimpiezaOficinas.vue';


export default defineComponent({
    name: 'HomeView',

    components: {
    ServicioLimpieza,
    },
});
</script>
<!-- 
    TEMPLATE
    -->

<!-- 
    SCRIPT
    -->
    
    