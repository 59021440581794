<template>
    <div class="review-card">
      <div class="review-header">
        <h3>{{ review.author }}</h3>
        <div class="stars">
          <span v-for="star in 5" :key="star" class="star" :class="{ filled: star <= review.rating }">&#9733;</span>
        </div>
      </div>
      <p class="review-comment">{{ review.comment }}</p>
      <a :href="review.link" target="_blank" rel="noopener noreferrer" class="review-link">Ver reseñas en Google</a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ReviewCard',
    props: {
      review: {
        type: Object,
        required: true,
        validator: (value) => {
          return (
            typeof value.author === 'string' &&
            typeof value.rating === 'number' &&
            value.rating >= 0 && value.rating <= 5 &&
            typeof value.comment === 'string' &&
            typeof value.link === 'string'
          );
        },
      },
    },
  };
  </script>
  
  <style scoped>
  .review-card {
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 12rem; /* Ajusta la altura según sea necesario */
    max-width: 24rem; /* Ajusta el ancho según sea necesario */
    overflow: hidden;
  }
  
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h3 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
  }
  
  .stars {
    display: flex;
  }
  
  .star {
    font-size: 1em;
    color: #ccc;
    margin-right: 4px;
  }
  
  .star.filled {
    color: #ffa500;
  }
  
  .review-comment {
    margin: 12px 0;
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Número de líneas antes de cortar el texto */
    -webkit-box-orient: vertical;
  }
  
  .review-link {
    text-decoration: none;
    color: #1a73e8;
    font-weight: bold;
  }
  
  .review-link:hover {
    text-decoration: underline;
  }
  </style>
  