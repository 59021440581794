<template>
  <div class="carousel-container">
    <button @click="prevGroup" class="carousel-button prev-button">
      <span class="arrow text-white">‹</span>
    </button>
    <div class="carousel-content">
      <ReviewCard
        v-for="(review, index) in groupedReviews[currentIndex]"
        :key="index"
        :review="review"
      />
    </div>
    <button @click="nextGroup" class="carousel-button next-button">
      <span class="arrow text-white">›</span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import ReviewCard from './ReviewCard.vue';

type Review = {
  author: string;
  rating: number;
  comment: string;
  link: string;
};

export default defineComponent({
  name: 'ReviewCarousel',
  components: { ReviewCard },
  props: {
    reviews: {
      type: Array as () => Review[],
      required: true,
    },
  },
  setup(props) {
    const currentIndex = ref(0);

    const groupedReviews = computed(() => {
      const groups: Review[][] = [];
      for (let i = 0; i < props.reviews.length; i += 3) {
        groups.push(props.reviews.slice(i, i + 3));
      }
      return groups;
    });

    const nextGroup = () => {
      currentIndex.value = (currentIndex.value + 1) % groupedReviews.value.length;
    };

    const prevGroup = () => {
      currentIndex.value = (currentIndex.value - 1 + groupedReviews.value.length) % groupedReviews.value.length;
    };

    return {
      currentIndex,
      groupedReviews,
      nextGroup,
      prevGroup,
    };
  },
});
</script>

<style scoped>
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box;
}

.carousel-content {
  display: flex;
  overflow: hidden;
  max-width: 80%;
}

.carousel-button {
  background-color: transparent;
  border: none;
  padding: 0 1rem;
  cursor: pointer;
  font-size: 2rem;
}

.carousel-button:focus {
  outline: none;
}

.arrow {
  font-size: 6rem;
  font-weight: 10;
}

.review-card {
  flex: 1 0 30%; /* Three cards per row */
  margin: 0 1rem;
  max-width: 30%;
}

.review-card h3 {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.review-card p {
  font-size: 1rem;
  margin: 0.5rem 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
