<template>
    <v-footer
        dark
    >
        <v-card
        
        class="text-center"
        >
            <v-card-text>
                <v-btn
                v-for="icon in icons"
                :key="icon"
                class="mx-3 white--text"
                icon
                :href="icon.link"
                target="_blank"
                >
                <v-icon size="24px">
                    {{ icon.s }}
                </v-icon>
                </v-btn>
            </v-card-text>

            <v-card-text class="white--text pt-0">
                ©Sarabia. 
                <br>
                <router-link to="/aviso_legal" target="_blank">Aviso legal</router-link> || <router-link to="/politica_de_privacidad" target="_blank">Política de privacidad</router-link>
                <br>  
                sarabiaserviciosintegrales@gmail.com
            </v-card-text>
            <v-divider></v-divider>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>Solo del Pais Vasco SL</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FooterComponent',
    data: () => ({
      icons: [
        {s:'mdi-facebook', link:'https://www.facebook.com/sarabia.serv.integrales/'},
        {s:'mdi-instagram', link:'https://www.instagram.com/sarabia.servicios.integrales/'}
      ],
    }),
  })
</script>

<style>

.v-footer{
   padding: 0px !important;

}
.v-footer .v-card{
   width:100%; 
   margin-top: 5% !important;
   margin-left: 0 !important;
   border:  none !important;
}
</style>