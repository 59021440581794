<template>
    <v-row>      
      <h1 style="margin-left: 5%; margin-bottom: 3%; text-align: center; width: 100%;">No dudes en contactar con nosotros:</h1>
    </v-row>
    <v-row no-gutters align="center" justify="start" align-content="center" >
      <v-row>
        <v-col cols=12 md=2 xs=0 sm=0 lg=2 align="right" ></v-col>
        <v-col cols=12 md=8 xs=12 sm=12 lg=8 align="right" >
          <v-row justify="end" align="center">
            <v-col cols=12 md=3 xs=5 sm=5 lg=4 justify="center" align="left" >
              <a @click="compose()"><v-img src="../../assets/contacto/presupuesto.png" class="glowable"></v-img></a>
            </v-col>
            <v-col cols=12 md=3 xs=1 sm=1 lg=4 align="right">
              <a href="https://wa.me/666320136" target="_blank"><v-img src="../../assets/contacto/whatsapp.png" class="glowable" /></a> 
            </v-col>
            <v-col cols=12 md=3 xs=1 sm=1 lg=4 align="right">
              <a href="tel:666320136" target="_blank"><v-img src="../../assets/contacto/telefono.png" class="glowable" /></a> 
            </v-col>
            <v-col lg="1"></v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-row>
    <v-row><v-spacer></v-spacer></v-row>
    <v-dialog v-model="dialog" max-width="500" min-width="500" style="overflow: visible;">
      <v-card elevation=5 class="mx-auto mt-2 text-center form" color="white" min-width="500"  max-width="500" style="overflow: visible;">
        <v-card-title class="contact-form_title">Contáctanos</v-card-title>
        <v-form
          ref="form" 
          lazy-validation
          class="v-form "
        >
          <v-field>
            <select   
              v-model="servicio"
              label="Servicio"
              outlined
              color="#4492c2"
              :rules="servicioRules"
              :error-messages="servicioErrors"
              @change="validateServicio"
            >
              <option disabled value="">Seleccione un servicio</option>
              <option value="limpieza por horas">Limpieza por horas</option>
              <option value="limpieza profesional profunda">Limpieza profesional profunda</option>
              <option value="limpieza profesional fin de obra">Limpieza profesional fin de obra</option>
              <option value="limpieza especializada luego de incendios o inundaciones">Limpieza especializada luego de incendios o inundaciones</option>
              <option value="limpieza de oficinas y comunidades">Limpieza de oficinas y comunidades</option>
              <option value="limpieza después de eventos y celebraciones">Limpieza después de eventos y celebraciones</option>
              <option value="limpieza de cristales">Limpieza de cristales</option>
              <option value="limpieza de balcones y terrazas">Limpieza de balcones y terrazas</option>
              <option value="síndrome diogenes">Síndrome diogenes</option>
            </select> 
          </v-field>

          <v-text-field class="tf"
            v-model="name"
            label="Nombre y apellidos"
            outlined
            color="#4492c2"
            :rules="nameRules"
            :error-messages="nameErrors"
            @input="validateName"
          ></v-text-field>

          <v-text-field class="tf"
            v-model="email"
            label="Correo electrónico"
            outlined
            color="#4492c2"
            :rules="emailRules"
            :error-messages="emailErrors"
            @input="validateEmail"
          ></v-text-field>
          <v-text-field class="tf"
            v-model="phone"
            label="Número de teléfono"
            outlined
            color="#4492c2"
            :rules="phoneRules"
            :error-messages="phoneErrors"
            @input="validatePhone"
          ></v-text-field>
          <v-textarea class="tf"
            v-model="message"
            label="Mensaje"
            outlined
            color="#4492c2"
            :rules="messageRules"
            :error-messages="messageErrors"
            required
            @input="validateMessage"
          ></v-textarea>

          <v-field>
            <v-checkbox required
              v-model="checkbox"
              :rules="checkboxRules"
              :error-messages="checkboxErrors"
              @input="validateCheckbox"
            >
              <template #label>
                He leido y acepto el <router-link to="/aviso_legal" target="_blank">Aviso legal</router-link> y la <router-link to="/politica_de_privacidad" target="_blank">Política de privacidad</router-link>.
              </template>
            </v-checkbox>
          </v-field>

          <v-row margin-top="1px">
            <v-col cols="12" sm="6" xl="6" align="center" justify="center">
              <v-btn color="#2fc1f2" class="mr-4 text-white" width=60% @click="saveDraft()" outlined >Cancelar</v-btn>
            </v-col>
            <v-col cols="12" sm="6" xl="6" align="center" justify="center">
              <v-btn
                color="#2fc1f2"
                class="mr-4 text-white"
                width=60%
                @click="submit(servicio,name,email,phone,message)"
              >
                Enviar
              </v-btn>
            </v-col>
          </v-row>
          
          
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script lang='ts'>
    import { defineComponent } from 'vue'

    export default defineComponent({
        components:{
        },
        name: 'ContactoComponent',

        //mounted() {<div class='sk-ww-google-reviews' data-embed-id='189108'></div> 
            // Load the external script when the component is mounted
            //const script = document.createElement('script');
            //script.src = 'https://widgets.sociablekit.com/google-reviews/widget.js';
            //script.async = true;
            //script.defer = true;
            //document.head.appendChild(script);
        //},
        data () {
            return {
              dialog: false,
              servicio: '',
              servicioRules: [
                (v: string) => !!v || 'Por favor, seleccione un servicio',
              ],
              name: '',
              nameRules: [
                (v: string) => !!v || 'Este campo es requerido',
              ],
              email: '',
              emailRules: [
                (v: string) => !!v || 'Este campo es requerido',
                (v: string) => /.+@.+\..+/.test(v) || 'Por favor, ingrese un correo electrónico válido',
              ],
              phone: '',
              phoneRules: [
                (v: string) => !!v || 'Este campo es requerido',
                (v: string) => /^\d+$/.test(v) || 'Por favor, ingrese un número de teléfono válido',
              ],
              message: '',
              messageRules: [
                (v: string) => !!v || 'Este campo es requerido',
                (v: string) => v.length >= 30 || 'El mensaje debe tener al menos 30 caracteres',
              ],
              checkbox: false,
              checkboxRules: [
                (v: boolean) => !!v || 'Debe aceptar los términos y condiciones',
              ],

              servicioErrors: [] as string[],
              nameErrors: [] as string[],
              emailErrors: [] as string[],
              phoneErrors: [] as string[],
              messageErrors: [] as string[],
              checkboxErrors: [] as string[],
            }
          },
        methods: {
          async submit(servicio: string, name: string, email: string, phone: string, message: string) {
            // Validar los campos del formulario inmediatamente antes de enviar
            this.validateServicio();
            this.validateName();
            this.validateEmail();
            this.validatePhone();
            this.validateMessage();
            this.validateCheckbox();

            // Verificar si hay errores en algún campo
            if (
              this.servicioErrors.length === 0 &&
              this.nameErrors.length === 0 &&
              this.emailErrors.length === 0 &&
              this.phoneErrors.length === 0 &&
              this.messageErrors.length === 0 &&
              this.checkboxErrors.length === 0
            ) {
              // Si no hay errores, proceder con el envío del formulario
              var data = {
                servicio: servicio,
                name: name,
                email: email,
                phone: phone,
                message: message,
              };

              /* LLamada a la API para pedir los datos de la tabla */
              await fetch('https://www.sarabiaserviciosintegrales.es/form_mail', {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              })
                .then((res) => res.json())
                .then((result) => {
                  if (result != null) {
                    console.log(result);
                    // Limpiar los campos después de enviar el formulario
                    this.name = '';
                    this.email = '';
                    this.phone = '';
                    this.message = '';
                    this.servicio = '';
                    alert("Formulario enviado.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });

              this.dialog = false;
            } else {
              // Si hay errores, mostrar un mensaje al usuario o realizar alguna acción adicional
              alert("Por favor, corrija los errores antes de enviar el formulario");
            }
          },
          compose() {
              this.dialog = true
          },
          saveDraft() {
              this.dialog = false
          },
          validateServicio() {
            this.servicioErrors = this.servicioRules
              .filter(rule => typeof rule(this.servicio) === 'string')
              .map(rule => rule(this.servicio) as string);
          },
          validateName() {
            this.nameErrors = this.nameRules
              .filter(rule => typeof rule(this.name) === 'string')
              .map(rule => rule(this.name) as string);
          },
          validateEmail() {
            this.emailErrors = this.emailRules
              .filter(rule => typeof rule(this.email) === 'string')
              .map(rule => rule(this.email) as string);
          },
          validatePhone() {
            this.phoneErrors = this.phoneRules
              .filter(rule => typeof rule(this.phone) === 'string')
              .map(rule => rule(this.phone) as string);
          },
          validateMessage() {
            this.messageErrors = this.messageRules
              .filter(rule => typeof rule(this.message) === 'string')
              .map(rule => rule(this.message) as string);
          },
          validateCheckbox() {
            this.checkboxErrors = this.checkboxRules
              .filter(rule => typeof rule(this.checkbox) === 'string')
              .map(rule => rule(this.checkbox) as string);
          },
        },
    })
</script>

<style>
body {
  background: #effbff;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
.text-img{
  width: 95% !important;
  -webkit-animation: fadein 2s ease-in alternate ;
  -moz-animation: fadein 2s ease-in alternate ;
  animation: fadein 2s ease-in alternate ;
  cursor: default;
  box-shadow:none;
  align-self: center;
  transition: transform 0.2s ease-in-out;
}
.fadein{
  -webkit-animation: fadein 2s ease-in alternate ;
  -moz-animation: fadein 2s ease-in alternate ;
  animation: fadein 2s ease-in alternate ;
  transition: transform 0.2s ease-in-out;
}

/*.text-img:active{
  transform: scale(3);
  z-index: 50;
  vertical-align: center;
  width: 20% !important;
  height: 50% !important;
  -moz-box-shadow: 0 0 1050px 20px #000;
  -webkit-box-shadow: 0 0 1050px 20px #000;
  box-shadow: 0 0 1050px 20px #000; 
}*/
.primer-texto h1 {
  text-align: center;
}

.primer-texto p{
  margin: 10px ;
  text-align: justify !important;
}
.text-img{
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: auto;
}

.sk_reviews_grid-item {
  display: none;
}

.sk_reviews_grid-item sk_reviews_badge_container{
  display: contents;
}

.v-card.form{
  border: 1px solid #8ed43e !important;
  width: 90% !important; 
  height: 100% !important;
}
.v-form .v-field__field {
  background-color:white !important;
  font-weight: bold;
/*  opacity: 0.2;
*/
}
.tf label{
  color:  #259444 !important;
}
.contact-form {
  background-color: #88c641;
  font-family: 16px !important;
  /* margin: 0 auto; */
  /* padding: 30px; */
  max-width: 100% ;
  
}

.contact-form .separator {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.separator {
  border-bottom: dotted 1px #ccc;
  margin-bottom: 15px;
}

.contact-form .form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form_title {
  color: black;
  font-weight: bold;
  font-size: 28px !important;
}

.contact-form select
.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 8px 7px;
  margin-bottom: 10px;
  outline: none;
}
select {  
  /* styling */
  background-color: white;
  border: none;
  border-radius: 4px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  border-radius: 3px;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  color: #259444 !important;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
  linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%),
  linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  width: 100%;
}

select:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select label{
  color:  #259444 !important;
}
.contact-form textarea {
  resize: none;
}

.contact-form .button {
  background: #2fc1f2;
  border: solid 1px #da552f;
  color: white;
  cursor: pointer;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
}

.contact-form .button:hover {
  background: #00a2ff;
  border: solid 1px #ea532a;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form textarea,
.contact-form .button {
  font-size: 15px;
  border-radius: 3px;
}
.row__services{
  padding-top: 10vh;
}
#col__servicesPainting{
  padding-top: 4.6vh;
}
@media only screen and (min-width: 1200px) {

#col__servicesPainting{
  padding-top: 4.6vh;
  padding-right: 20vw;
}
#col__servicesCleaning{
  padding-left: 20vw;
}
}

.glowable{
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s; 
}
.glowable:hover {
  opacity: 0.8;
  transform: scale(1.10);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.04);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.10);
    opacity: 1;
  }
}

a:hover {
  opacity: 70%;
}

select {  
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  padding: 0.5em 3.5em 0.5em 1em;
  color: #259444 !important;
  width: 100%;
}

.v-img {
  margin: 6%;
}
.contact-form select {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: white;
}

.contact-form select:focus {
  border-color: green;
  outline: 0;
}
</style>

