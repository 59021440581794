<template>
  <v-app>
    <v-app-bar v-if="$route.name != 'Politica de privacidad' && $route.name != 'Aviso legal'" dense flat color="white">
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 logo"
            contain
            src="../src/assets/logo.png"
            transition="scale-transition"
            max-width="160"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <router-link to="/">
        <v-btn text>
          Inicio
        </v-btn>
      </router-link>

      <router-link to="/quienes-somos">
        <v-btn text>
          Quienes Somos
        </v-btn>
      </router-link>

      <v-menu open-on-hover>
        <template v-slot:activator="{ props }">
          <v-btn text v-bind="props" v-on="props">
            Servicios
          </v-btn>
        </template>

        <v-list>
          <router-link v-for="(item, index) in items_services" :to="item.url" :key="index">
            <v-list-item>
              {{ item.name }}
            </v-list-item>
          </router-link>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="full-width">
      <router-view class="full-width"/>
    </v-main>
    <FooterComponent v-if="$route.name != 'Politica de privacidad' && $route.name != 'Aviso legal'"/>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FooterComponent from './components/footer.vue'

export default defineComponent({
  name: 'App',

  components: {
    FooterComponent,
  },

  data() {
    return {
      items_services: [
        {
          url: '/limpieza-por-horas',
          name: 'Limpieza por horas',
        },
        {
          url: '/limpieza-profesional-profunda',
          name: 'Limpieza profesional profunda',
        },
        {
          url: '/limpieza-profesional-fin-de-obra',
          name: 'Limpieza profesional fin de obra',
        },
        {
          url: '/limpieza-especializada-incendios-inundaciones',
          name: 'Limpieza especializada luego de incendios o inundaciones',
        },
        {
          url: '/limpieza-oficinas-y-comunidades',
          name: 'Limpieza de oficinas y comunidades',
        },
        {
          url: '/limpieza-eventos-celebraciones',
          name: 'Limpieza después de eventos y celebraciones',
        },
        {
          url: '/limpieza-cristales',
          name: 'Limpieza de cristales',
        },
        {
          url: '/limpieza-balcones-terrazas',
          name: 'Limpieza de balcones / terrazas',
        },
        {
          url: '/sindrome-diogenes',
          name: 'Síndrome Diógenes',
        },
      ],
      items_products: [
        {
          url: '/service1',
          name: 'Químicos de limpieza',
        },
        {
          url: '/service2',
          name: 'Ambientadores',
        },
      ],
    }
  },
})
</script>

<style type="text/css">
  html, body, #app {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .logo-bar {
    height: 90px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .v-footer .v-card {
    background-color: #68c8e8;
    border: 0px solid #2fc1f2 !important;
  }

  .fb-icon {
    background-color: transparent;
  }

  .v-toolbar-title .logo {
    transform: scale(0.7); /* Ajusta el tamaño del logo según tus preferencias */
  }

  /* Estilos para enlaces */
  a {
    color: black; /* Cambia el color a negro o el color deseado para enlaces no visitados */
    text-decoration: none; /* Elimina el subrayado por defecto en los enlaces a*/
  }

  a:visited {
    color: black; /* Cambia el color a negro o el color deseado para enlaces visitados */
  }

  #app {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .v-main {
    flex: 1;
  }

  footer {
    flex-shrink: 0;
  }
</style>
