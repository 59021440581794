import { createStore } from "vuex";

export default createStore({
  state: {
    userRole: null,
    userEmail: null,
    userPassword: null,
  },
  getters: {
    // Definir getters si es necesario
  },
  mutations: {
    setUserRole(state, role) {
      state.userRole = role;
      setCookie('userRole', role);
    },
    setUserEmail(state, email) {
      state.userEmail = email;
      setCookie('userEmail', email);
    },
    setUserPassword(state, password) {
      state.userPassword = password;
      setCookie('userPassword', password);
    },
  },
  actions: {
    // Definir acciones si es necesario
  },
  modules: {
    // Definir módulos si es necesario
  },
  // Restaurar el estado de Vuex desde una cookie al inicializar la tienda
  strict: process.env.NODE_ENV !== "production",
  plugins: [store => initializeStoreFromCookie(store)],
});

// Función para inicializar el estado de Vuex desde una cookie
function initializeStoreFromCookie(store:any) {
  // Verificar si hay datos de usuario en una cookie
  const userRoleCookie = getCookie('userRole');
  if (userRoleCookie) {
    store.commit('setUserRole', userRoleCookie);
  }
  const userEmailCookie = getCookie('userEmail');
  if (userEmailCookie) {
    store.commit('setUserEmail', userRoleCookie);
  }
  const userPasswordCookie = getCookie('userPassword');
  if (userPasswordCookie) {
    store.commit('setUserPassword', userRoleCookie);
  }
}
function setCookie(name: string, value: string) {
  // Almacenar en el almacenamiento local
  localStorage.setItem(name, value);
  console.log(`Cookie ${name} set to ${value}`);

}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2 && parts[1]) {
    return parts[1].split(';').shift();
  }
  // Si no se encontró la cookie o la parte requerida, puedes manejarlo como desees.
  return null; // O cualquier otro valor que prefieras
}