import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'

const cache = new InMemoryCache()
const httpLink = createHttpLink({
    uri: 'https://localhost:44343/graphql',
})
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
})
loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .provide(DefaultApolloClient, apolloClient)
  .mount('#app')
