import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
// import MachinesView from "../views/MachinesView.vue";

// import BlogView from "../views/BlogView.vue";
// import SuccessStoriesView from "../views/SuccessStoriesView.vue";
// import BusinessPartnersView from "../views/BusinessPartnersView.vue";
import ServicioLimpiezaPorHoras from "../views/ServicioLimpiezaPorHorasView.vue"
import ServicioLimpiezaProfesionalProfunda from "../views/ServicioLimpiezaProfesionalProfundaView.vue"
import ServicioLimpiezaProfesionalFinDeObra from "../views/ServicioLimpiezaProfesionalFinDeObraView.vue"
import ServicioLimpiezaEspecializadaIncendios from "../views/ServicioLimpiezaEspecializadaIncendiosView.vue"
import ServicioLimpiezaOficinas from "../views/ServicioLimpiezaOficinasView.vue"
import ServicioLimpiezaEventos from "../views/ServicioLimpiezaEventosView.vue"
import ServicioLimpiezaCristales from "../views/ServicioLimpiezaCristalesView.vue"
import ServicioLimpiezaBalcones from "../views/ServicioLimpiezaBalconesTerrazasView.vue"
import ServicioDiogenes from "../views/ServicioSindromeDeDiogenesView.vue"
import QuienesSomos from "../views/QuienesSomosView.vue"
import PoliticaDePrivacidad from "../views/PoliticaDePrivacidad.vue"
import AvisoLegal from "../views/AvisoLegal.vue"




const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/quienes-somos",
    name: "Quienes somos",
    component: QuienesSomos,
  },
  {
    path: "/limpieza-por-horas",
    name: "Servicios de limpieza por horas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaPorHoras
  },
  {
    path: "/limpieza-profesional-profunda",
    name: "Servicios de limpieza profesional profunda",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaProfesionalProfunda
  },
  {
    path: "/limpieza-profesional-fin-de-obra",
    name: "Servicios de limpieza profesional fin de obra",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaProfesionalFinDeObra
  },
  {
    path: "/limpieza-especializada-incendios-inundaciones",
    name: "Servicios de limpieza especializada luego de incendios o inundaciones",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaEspecializadaIncendios
  },
  {
    path: "/limpieza-oficinas-y-comunidades",
    name: "Servicios de limpieza de oficinas y comunidades",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaOficinas
  },
  {
    path: "/limpieza-eventos-celebraciones",
    name: "Servicios de limpieza después de eventos y celebraciones",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaEventos
  },
  {
    path: "/limpieza-profesional-fin-de-obra",
    name: "Servicios de limpieza profesional fin de obra",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaProfesionalFinDeObra
  },
  {
    path: "/limpieza-cristales",
    name: "Servicios de limpieza de cristales",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaCristales
  },
  {
    path: "/limpieza-balcones-terrazas",
    name: "Servicios de limpieza de balcones / terrazas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioLimpiezaBalcones
  },
  {
    path: "/sindrome-diogenes",
    name: "Servicios de síndrome de Diógenes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ServicioDiogenes
  },
  /*{
    path: "/maquinas",
    name: "Machines",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MachinesView
  },
 /* {
    path: '/maquinas/:id',
    name: 'MachineDetails', // Nombre de la ruta
    component: MachineDetailsView, // Componente de vista de detalle de máquina
    props: true, // Permite pasar props a la vista
  },*/
  /*{
    path: "/blog",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BlogView
  },
  {
    path: "/casos-de-exito",
    name: "SuccessStories",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SuccessStoriesView
  },
  // {
  //   path: "/business-partners",
  //   name: "Business Partners",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: BusinessPartnersView
  // },*/
  {
    path: "/politica_de_privacidad",
    name: "Politica de privacidad",
    component: PoliticaDePrivacidad
  },
  {
    path: "/aviso_legal",
    name: "Aviso legal",
    component: AvisoLegal
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const defaultTitle = 'Sarabia Servicios Integrales'; 
router.beforeEach((to, from, next) => {
  document.title = defaultTitle;
  next();
});

export default router;
