<template>
    <body style="margin-left: 30px; margin-right: 30px; background-color: white;">
        <header class="text-center" style="box-sizing: border-box; font-family: Roboto, sans-serif; text-align: center !important; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
            <header class="text-center" style="box-sizing: border-box; font-family: Roboto, sans-serif; text-align: center !important; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
                <h2 class="m-auto" style="box-sizing: border-box; font-family: Lato, sans-serif; margin: auto !important; font-weight: 700; line-height: 1.4em; font-size: 2rem;">AVISO LEGAL Y CONDICIONES GENERALES DE USO</h2>
                <h4 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.5rem;">https://sarabiaserviciosintegrales.es/</h4>
            </header>
            <div style="box-sizing: border-box; font-family: Roboto, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
                <h3 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.7rem;">I. INFORMACI&Oacute;N GENERAL</h3>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">En cumplimiento con el deber de informaci&oacute;n dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y el Comercio Electr&oacute;nico (LSSI-CE) de 11 de julio, se facilitan a continuaci&oacute;n los siguientes datos de informaci&oacute;n general de este sitio web:</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">La titularidad de este sitio web,&nbsp;https://sarabiaserviciosintegrales.es/, (en adelante, Sitio Web) la ostenta:&nbsp;SOLO DEL PAIS VASCO SOCIEDAD LIMITADA., provista de NIF:&nbsp;B09671157 e inscrita en:&nbsp;Registro Mercantil de Bizkaia con los siguientes datos registrales:&nbsp;, cuyo representante es:&nbsp;Sarabia Servicios Integrales, y cuyos datos de contacto son:</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Direcci&oacute;n:&nbsp;Larrakoetxe-Derio Hiribidea, 3, 48180 Loiu, Bizkaia</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Tel&eacute;fono de contacto:&nbsp;+34 666 32 01 36</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Email de contacto:&nbsp;limpiezasarabiaweb@gmail.com</p>
                <h3 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.7rem;">II. T&Eacute;RMINOS Y CONDICIONES GENERALES DE USO</h3>
                <h4 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.5rem;">El objeto de las condiciones: El Sitio Web</h4>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la utilizaci&oacute;n del Sitio Web. A los efectos de las presentes Condiciones se entender&aacute; como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma est&aacute;tica como de forma din&aacute;mica, es decir, el &aacute;rbol de navegaci&oacute;n; y todos los elementos integrados tanto en los interfaces de pantalla como en el &aacute;rbol de navegaci&oacute;n (en adelante, Contenidos) y todos aquellos servicios o recursos en l&iacute;nea que en su caso ofrezca a los Usuarios (en adelante, Servicios).</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentaci&oacute;n y configuraci&oacute;n del Sitio Web y de los Contenidos y Servicios que en &eacute;l pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento&nbsp;Sarabia Servicios Integrales pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El acceso al Sitio Web por el Usuario tiene car&aacute;cter libre y, por regla general, es gratuito sin que el Usuario tenga que proporcionar una contraprestaci&oacute;n para poder disfrutar de ello, salvo en lo relativo al coste de conexi&oacute;n a trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado el Usuario.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">La utilizaci&oacute;n de alguno de los Contenidos o Servicios del Sitio Web podr&aacute; hacerse mediante la suscripci&oacute;n o registro previo del Usuario.</p>
                <h4 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.5rem;">El Usuario</h4>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El acceso, la navegaci&oacute;n y uso del Sitio Web,&nbsp;confiere la condici&oacute;n de Usuario, por lo que se aceptan, desde que se inicia la navegaci&oacute;n por el Sitio Web, todas las Condiciones aqu&iacute; establecidas, as&iacute; como sus ulteriores modificaciones, sin perjuicio de la aplicaci&oacute;n de la correspondiente normativa legal de obligado cumplimiento seg&uacute;n el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el Sitio Web.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El Sitio Web de&nbsp;Sarabia Servicios Integrales proporciona gran diversidad de informaci&oacute;n, servicios y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extender&aacute; a:</p>
                <ul style="box-sizing: border-box; font-family: Roboto, sans-serif; padding-left: 2rem; margin-top: 0px; margin-bottom: 1rem;">
                    <li style="box-sizing: border-box; font-family: Roboto, sans-serif;">Un uso de la informaci&oacute;n, Contenidos y/o Servicios y datos ofrecidos por&nbsp;Sarabia Servicios Integrales sin que sea contrario a lo dispuesto por las presentes Condiciones, la Ley, la moral o el orden p&uacute;blico, o que de cualquier otro modo puedan suponer lesi&oacute;n de los derechos de terceros o del mismo funcionamiento del Sitio Web.</li>
                    <li style="box-sizing: border-box; font-family: Roboto, sans-serif;">La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por&nbsp;Sarabia Servicios Integrales para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificar&aacute; de forma inmediata a&nbsp;Sarabia Servicios Integrales acerca de cualquier hecho que permita el uso indebido de la informaci&oacute;n registrada en dichos formularios, tales como, pero no solo, el robo, extrav&iacute;o, o el acceso no autorizado a identificadores y/o contrase&ntilde;as, con el fin de proceder a su inmediata cancelaci&oacute;n.</li>
                </ul>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El mero acceso a este Sitio Web no supone entablar ning&uacute;n tipo de relaci&oacute;n de car&aacute;cter comercial entre&nbsp;Sarabia Servicios Integrales y el Usuario.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El Usuario declara ser mayor de edad y disponer de la capacidad jur&iacute;dica suficiente para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de&nbsp;Sarabia Servicios Integrales no se dirige a menores de edad.&nbsp;Sarabia Servicios Integrales declina cualquier responsabilidad por el incumplimiento de este requisito.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El Sitio Web est&aacute; dirigido principalmente a Usuarios residentes en&nbsp;Espa&ntilde;a.&nbsp;Sarabia Servicios Integrales no asegura que el Sitio Web cumpla con legislaciones de otros pa&iacute;ses, ya sea total o parcialmente. Si el Usuario reside o tiene su domiciliado en otro lugar y decide acceder y/o navegar en el Sitio Web lo har&aacute; bajo su propia responsabilidad, deber&aacute; asegurarse de que tal acceso y navegaci&oacute;n cumple con la legislaci&oacute;n local que le es aplicable, no asumiendo&nbsp;Sarabia Servicios Integrales responsabilidad alguna que se pueda derivar de dicho acceso.</p>
                <h3 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.7rem;">III. ACCESO Y NAVEGACI&Oacute;N EN EL SITIO WEB: EXCLUSI&Oacute;N DE GARANT&Iacute;AS Y RESPONSABILIDAD</h3>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios.&nbsp;Sarabia Servicios Integrales har&aacute; todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que est&eacute; libre de error.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a trav&eacute;s de este Sitio Web, est&eacute; libre de error o cause un da&ntilde;o al sistema inform&aacute;tico (software y hardware) del Usuario. En ning&uacute;n caso&nbsp;Sarabia Servicios Integrales ser&aacute; responsable por las p&eacute;rdidas, da&ntilde;os o perjuicios de cualquier tipo que surjan por el acceso, navegaci&oacute;n y el uso del Sitio Web, incluy&eacute;ndose, pero no limit&aacute;ndose, a los ocasionados a los sistemas inform&aacute;ticos o los provocados por la introducci&oacute;n de virus.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales tampoco se hace responsable de los da&ntilde;os que pudiesen ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En particular, no se hace responsable en modo alguno de las ca&iacute;das, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir.</p>
                <h3 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.7rem;">IV. POL&Iacute;TICA DE ENLACES</h3>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Se informa que el Sitio Web de&nbsp;Sarabia Servicios Integrales pone o puede poner a disposici&oacute;n de los Usuarios medios de enlace (como, entre otros, links, banners, botones), directorios y motores de b&uacute;squeda que permiten a los Usuarios acceder a sitios web pertenecientes y/o gestionados por terceros.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">La instalaci&oacute;n de estos enlaces, directorios y motores de b&uacute;squeda en el Sitio Web tiene por objeto facilitar a los Usuarios la b&uacute;squeda de y acceso a la informaci&oacute;n disponible en Internet, sin que pueda considerarse una sugerencia, recomendaci&oacute;n o invitaci&oacute;n para la visita de los mismos.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales no ofrece ni comercializa por s&iacute; ni por medio de terceros los productos y/o servicios disponibles en dichos sitios enlazados.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Asimismo, tampoco garantizar&aacute; la disponibilidad t&eacute;cnica, exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales en ning&uacute;n caso revisar&aacute; o controlar&aacute; el contenido de otros sitios web, as&iacute; como tampoco aprueba, examina ni hace propios los productos y servicios, contenidos, archivos y cualquier otro material existente en los referidos sitios enlazados.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales no asume ninguna responsabilidad por los da&ntilde;os y perjuicios que pudieran producirse por el acceso, uso, calidad o licitud de los contenidos, comunicaciones, opiniones, productos y servicios de los sitios web no gestionados por&nbsp;Sarabia Servicios Integrales y que sean enlazados en este Sitio Web.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El Usuario o tercero que realice un hiperv&iacute;nculo desde una p&aacute;gina web de otro, distinto, sitio web al Sitio Web de&nbsp;Sarabia Servicios Integrales deber&aacute; saber que:</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">No se permite la reproducci&oacute;n &mdash;total o parcialmente&mdash; de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorizaci&oacute;n expresa de&nbsp;Sarabia Servicios Integrales.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">No se permite tampoco ninguna manifestaci&oacute;n falsa, inexacta o incorrecta sobre el Sitio Web de&nbsp;Sarabia Servicios Integrales, ni sobre los Contenidos y/o Servicios del mismo.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">A excepci&oacute;n del hiperv&iacute;nculo, el sitio web en el que se establezca dicho hiperenlace no contendr&aacute; ning&uacute;n elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jur&iacute;dico espa&ntilde;ol, salvo autorizaci&oacute;n expresa de&nbsp;Sarabia Servicios Integrales.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El establecimiento del hiperv&iacute;nculo no implicar&aacute; la existencia de relaciones entre&nbsp;Sarabia Servicios Integrales y el titular del sitio web desde el cual se realice, ni el conocimiento y aceptaci&oacute;n de&nbsp;Sarabia Servicios Integrales de los contenidos, servicios y/o actividades ofrecidas en dicho sitio web, y viceversa.</p>
                <h3 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.7rem;">V. PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales por s&iacute; o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, as&iacute; como de los elementos contenidos en el mismo (a t&iacute;tulo enunciativo y no exhaustivo, im&aacute;genes, sonido, audio, v&iacute;deo, software o textos, marcas o logotipos, combinaciones de colores, estructura y dise&ntilde;o, selecci&oacute;n de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Ser&aacute;n, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jur&iacute;dico espa&ntilde;ol, si&eacute;ndoles aplicables tanto la normativa espa&ntilde;ola y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por Espa&ntilde;a.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducci&oacute;n, la distribuci&oacute;n y la comunicaci&oacute;n p&uacute;blica, incluida su modalidad de puesta a disposici&oacute;n, de la totalidad o parte de los contenidos de esta p&aacute;gina web, con fines comerciales, en cualquier soporte y por cualquier medio t&eacute;cnico, sin la autorizaci&oacute;n de&nbsp;Sarabia Servicios Integrales.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de&nbsp;Sarabia Servicios Integrales. Podr&aacute; visualizar los elementos del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte f&iacute;sico siempre y cuando sea, exclusivamente, para su uso personal. El Usuario, sin embargo, no podr&aacute; suprimir, alterar, o manipular cualquier dispositivo de protecci&oacute;n o sistema de seguridad que estuviera instalado en el Sitio Web.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violaci&oacute;n de los derechos de protecci&oacute;n de la propiedad intelectual, deber&aacute; comunicarlo inmediatamente a&nbsp;Sarabia Servicios Integrales a trav&eacute;s de los datos de contacto del apartado de INFORMACI&Oacute;N GENERAL de este Aviso Legal y Condiciones Generales de Uso.</p>
                <h3 style="box-sizing: border-box; font-family: Lato, sans-serif; margin-top: 0px; margin-bottom: 0.5rem; font-weight: 700; line-height: 1.4em; font-size: 1.7rem;">VI. ACCIONES LEGALES, LEGISLACI&Oacute;N APLICABLE Y JURISDICCI&Oacute;N</h3>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">Sarabia Servicios Integrales se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilizaci&oacute;n indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.</p>
                <p style="box-sizing: border-box; font-family: Roboto, sans-serif; margin-top: 0px; margin-bottom: 1rem; line-height: 1.7rem;">La relaci&oacute;n entre el Usuario y&nbsp;Sarabia Servicios Integrales se regir&aacute; por la normativa vigente y de aplicaci&oacute;n en el territorio espa&ntilde;ol. De surgir cualquier controversia en relaci&oacute;n con la interpretaci&oacute;n y/o a la aplicaci&oacute;n de estas Condiciones las partes someter&aacute;n sus conflictos a la jurisdicci&oacute;n ordinaria someti&eacute;ndose a los jueces y tribunales que correspondan conforme a derecho.</p>
            </div>
        </header>
        <div style="box-sizing: border-box; font-family: Roboto, sans-serif; color: rgb(33, 37, 41); font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"><br></div>
    </body>
</template>