<template>
  <v-container fluid style="padding: 0; margin: 0; width: 100vw; max-width: 100vw;">
    
    <v-row class="full-width" style="width: 100%;">
      <div class="background-overlay fadein">
        <!-- Solid blue section for the title -->
        <div class="solid-blue-section">
          <h1 style="font-size: xxx-large; margin: 6%">Servicios de limpieza a medida</h1>
        </div>
        <!-- Semi-transparent blue section for the description -->
        <div class="semi-transparent-section">
          <p>Nuestros servicios de limpieza a medida están diseñados para adaptarse perfectamente a tus necesidades específicas. Ya sea que requieras una limpieza regular para mantener tu hogar o lugar de trabajo impecable, o necesites una limpieza profunda y detallada para una ocasión especial, nuestro equipo altamente capacitado está aquí para ayudarte.</p>
        </div>
      </div>
    </v-row>
    <div class="servicios-principales" padding-left=100%>
      <v-row>      
        <h1 style="margin-top: 3%; margin-bottom: 3%; text-align: center; width: 100%;">Conoce nuestros servicios principales:</h1>
      </v-row>
      <v-row style="align-items: center; margin-left: 16%;">
        <v-col cols="12" xs="6" md="2">
          <router-link to="/limpieza-profesional-profunda">
            <v-img src="../assets/categorias_home/limpieza_profesional_profunda.png" alt=""/>
          </router-link>
        </v-col>
        <v-col cols="12" xs="6" md="2" >
          <router-link to="/limpieza-profesional-fin-de-obra">
            <v-img style="padding: px;" src="../assets/categorias_home/limpieza_fin_de_obra.png" alt=""/>
          </router-link>
        </v-col>
        <v-col cols="12" xs="6" md="2">
          <router-link to="/limpieza-oficinas-y-comunidades">
            <v-img src="../assets/categorias_home/limpieza_oficinas_y_comunidades.png" alt=""/>
          </router-link>
        </v-col>
        <v-col cols="12" xs="6" md="2">
          <router-link to="/sindrome-diogenes">
            <v-img src="../assets/categorias_home/limpieza_diogenes.png" alt=""/>
          </router-link>
        </v-col>
        <v-col cols="12" xs="6" md="2">
          <router-link to="/limpieza-por-horas">
            <v-img src="../assets/categorias_home/limpieza_por_horas.png" alt=""/>
          </router-link>
        </v-col>
      </v-row>
    </div>
    <v-row class="full-width" style="margin-bottom: 3%; margin-top: 3%;">
      <div class="background-overlay fadein">
        <!-- Solid blue section for the title -->
        <div class="solid-green-section">
          <v-row>
            <v-col cols="12" md="12">
              <h1 style="font-size: xxx-large; margin: 5%">Algunas opiniones de nuestros clientes</h1>
            </v-col>
          </v-row>
        </div>
        <!-- Semi-transparent blue section for the description -->
        <div class="semi-transparent-green-section">
          <ReviewCarousel :reviews="reviews" />
        </div>
      </div>
    </v-row>
    <contacto class="fadein" style="margin-top: 9%;"></contacto>
    <!-- <v-row class="full-width align-center justify-start" cursor= "default" no-gutters  style="cursor: default; margin-top: 5%; margin-left: 3%;margin-right: 3%;">
      <v-col cols=12 md=6 xs=12 sm=10 lg=6 align="start">
        <div class="home">
          <div class="body">
            <div class="primer-texto">
              <h1 class="fadein" style="text-align: left;margin-left: 13%;margin-bottom: 2%;font-size: large;">SOMOS TUS ALIADOS</h1>
              <v-img src='../assets/logo.png' class="text-img center"/>  
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols=12 md=6 xs=12 sm=10 lg=6 align="center">
        <v-row cursor= "default" align="center">
          <v-col cols=12 md=6 xs=12 sm=12 lg=6 justify="center" align="center"><v-img src='../assets/aspirando.png' class="text-img" /></v-col>
          <v-col cols=12 md=6 xs=12 sm=12 lg=6 justify="center" align="center"><v-img src='../assets/Mopa.png' class="text-img" /></v-col>
        </v-row>
      </v-col>
    </v-row>     -->
</v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import Contacto from './Partials/Contacto.vue';
import ReviewCarousel from './Partials/ReviewCarousel.vue';


export default defineComponent({
  components:{
    Contacto,
    ReviewCarousel,
},
  name: 'HomeComponent',

  //mounted() {<div class='sk-ww-google-reviews' data-embed-id='189108'></div>
    // Load the external script when the component is mounted
    //const script = document.createElement('script');
    //script.src = 'https://widgets.sociablekit.com/google-reviews/widget.js';
    //script.async = true;
    //script.defer = true;
    //document.head.appendChild(script);
  //},
  data () {
    return {
      dialog: false,
      name:'',
      email:'',
      phone:'',
      message:'',
      servicio:'',
      servicios: [
        {nombreServicio:'Limpieza',descripcion:'Servicio de limpieza', src:require('../assets/serviciosLimpieza.png')},
        {nombreServicio:'Pintura',descripcion:'Servicio de Pintura', src:require('../assets/serviciosPintura.png')}],
        reviews: [
        {
          author: 'Irene Ocio Garcia',
          rating: 5,
          comment: 'Contratamos para un servicio de limpieza post obra tras una reforma integral de vivienda, y no podríamos haber elegido mejor. Karina y Marcos siempre atentos, facilitando la comunicación en todo momento, adaptándose a nuestra disponibilidad y realizando el trabajo además en una única jornada. Han dejado nuestro piso impecable.',
          link: 'https://www.google.com/maps/place/Sarabia+Servicios+Integrales/@43.3125129,-2.9209701,17z/data=!4m8!3m7!1s0xd4e5148e6e463f3:0x4c0572bef5863e7!8m2!3d43.312509!4d-2.9183952!9m1!1b1!16s%2Fg%2F11hrxs15mm?hl=es&entry=ttu',
        },
        // Add more review objects here
        // Ensure at least 6 reviews for the carousel
        {
          author: 'Cris Aira',
          rating: 5,
          comment: 'Personal encantador, yo conocí a Marcos que junto su equipo un trabajo excelente y serviciales en todo. Os animo a contar con ellos ..son geniales y Marcos responsable y muy buena persona animo no busquéis más. Gracias',
          link: 'https://www.google.com/maps/place/Sarabia+Servicios+Integrales/@43.3125129,-2.9209701,17z/data=!4m8!3m7!1s0xd4e5148e6e463f3:0x4c0572bef5863e7!8m2!3d43.312509!4d-2.9183952!9m1!1b1!16s%2Fg%2F11hrxs15mm?hl=es&entry=ttu',
        },
        {
          author: 'Marta Sarasketa',
          rating: 5,
          comment: 'Tengo contratado el servicio desde hacer años y estoy muy satisfecha. Grandes profesionales, empresa de confianza.',
          link: 'https://www.google.com/maps/place/Sarabia+Servicios+Integrales/@43.3125129,-2.9209701,17z/data=!4m8!3m7!1s0xd4e5148e6e463f3:0x4c0572bef5863e7!8m2!3d43.312509!4d-2.9183952!9m1!1b1!16s%2Fg%2F11hrxs15mm?hl=es&entry=ttu',
        },
        {
          author: 'Abms Abms',
          rating: 5,
          comment: 'Arreglo terraza exterior. Gran profesionalidad y muy atentos desde Karina la chica q coordina, hasta Marcos y su chico. Trato impecable y trabajo impecable. Mereció la pena y los guardo para futuros proyectos. Mil gracias, es difícil hoy en día encontrar profesionalidad, trato impecable y a buen precio.',
          link: 'https://www.google.com/maps/place/Sarabia+Servicios+Integrales/@43.3125129,-2.9209701,17z/data=!4m8!3m7!1s0xd4e5148e6e463f3:0x4c0572bef5863e7!8m2!3d43.312509!4d-2.9183952!9m1!1b1!16s%2Fg%2F11hrxs15mm?hl=es&entry=ttu',
        },
        {
          author: 'Pilar Lopez Diaz',
          rating: 5,
          comment: 'Estoy encantada tanto del trato como de la eficiencia del trabajo. Volveré a repetir para después del verano!!! Gracias',
          link: 'https://www.google.com/maps/place/Sarabia+Servicios+Integrales/@43.3125129,-2.9209701,17z/data=!4m8!3m7!1s0xd4e5148e6e463f3:0x4c0572bef5863e7!8m2!3d43.312509!4d-2.9183952!9m1!1b1!16s%2Fg%2F11hrxs15mm?hl=es&entry=ttu',
        },
        {
          author: 'Rober Gallo',
          rating: 5,
          comment: 'Han realizado un trabajo fantástico en la limpieza de unos ventanales. Rápido y muy profesional. Un acierto.',
          link: 'https://www.google.com/maps/place/Sarabia+Servicios+Integrales/@43.3125129,-2.9209701,17z/data=!4m8!3m7!1s0xd4e5148e6e463f3:0x4c0572bef5863e7!8m2!3d43.312509!4d-2.9183952!9m1!1b1!16s%2Fg%2F11hrxs15mm?hl=es&entry=ttu',
        },
      ],
    }
  },
  methods: {
    async submit(servicio: string,name: string,email: string,phone: string,message: string) {
      var data = {
        servicio: servicio,
        name: name,
        email: email,
        phone: phone,
        message: message,
      };

      /*  LLamada a la api para pedir los datos de la tabla */
      await fetch('https://www.sarabiaserviciosintegrales.es/form_mail', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          console.log(result);
          this.name='';
          this.email='';
          this.phone='';
          this.message='';
          this.servicio='';
          alert("Formulario enviado.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
      this.dialog = false
    },
    compose() {
        this.dialog = true
    },
    saveDraft() {
        this.dialog = false
    },
  },
})
</script>


<!--
  STYLE
 -->
<style>
body {
  background: #effbff;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}


.text-img{
  width: 95% !important;
  -webkit-animation: fadein 2s ease-in alternate ;
  -moz-animation: fadein 2s ease-in alternate ;
  animation: fadein 2s ease-in alternate ;
  cursor: default;
  box-shadow:none;
  align-self: center;
  transition: transform 0.2s ease-in-out;
}
.fadein{
  -webkit-animation: fadein 2s ease-in alternate ;
  -moz-animation: fadein 2s ease-in alternate ;
  animation: fadein 2s ease-in alternate ;
  transition: transform 0.2s ease-in-out;
}

/*.text-img:active{
  transform: scale(3);
  z-index: 50;
  vertical-align: center;
  width: 20% !important;
  height: 50% !important;
  -moz-box-shadow: 0 0 1050px 20px #000;
  -webkit-box-shadow: 0 0 1050px 20px #000;
  box-shadow: 0 0 1050px 20px #000;
}*/
.primer-texto h1 {
  text-align: center;
}

.primer-texto p{
  margin: 10px ;
  text-align: justify !important;
}
.text-img{
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: auto;
}

.sk_reviews_grid-item {
  display: none;
}

.sk_reviews_grid-item sk_reviews_badge_container{
  display: contents;
}

.v-card.form{
  border: 1px solid #8ed43e !important;
  width: 80% !important;
  height: 50% !important;
}
.v-form .v-field__field {
  background-color:white !important;
  font-weight: bold;
/*  opacity: 0.2;
*/
}
.tf label{
  color:  #259444 !important;
}
.contact-form {
  background-color: #88c641;
  font-family: 16px !important;
  /* margin: 0 auto; */
  /* padding: 30px; */
  max-width: 100% ;

}

.contact-form .separator {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.separator {
  border-bottom: dotted 1px #ccc;
  margin-bottom: 15px;
}

.contact-form .form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form_title {
  color: black;
  font-weight: bold;
  font-size: 28px !important;
}

.contact-form select
.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 10px 7px;
  margin-bottom: 15px;
  outline: none;
}
select {
  /* styling */
  background-color: white;
  border: none;
  border-radius: 4px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  border-radius: 3px;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  color: #259444 !important;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
  linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%),
  linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  width: 100%;
}

select:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select label{
  color:  #259444 !important;
}
.contact-form textarea {
  resize: none;
}

.contact-form .button {
  background: #2fc1f2;
  border: solid 1px #da552f;
  color: white;
  cursor: pointer;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
}

.contact-form .button:hover {
  background: #00a2ff;
  border: solid 1px #ea532a;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form textarea,
.contact-form .button {
  font-size: 15px;
  border-radius: 3px;
}
.row__services{
  padding-top: 10vh;
}
#col__servicesPainting{
  padding-top: 4.6vh;
}
@media only screen and (min-width: 1200px) {

#col__servicesPainting{
  padding-top: 4.6vh;
  padding-right: 20vw;
}
#col__servicesCleaning{
  padding-left: 20vw;
}
}

.glowable{
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
}
.glowable:hover {
  opacity: 0.8;
  transform: scale(1.10);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.04);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.10);
    opacity: 1;
  }
}

a:hover {
  opacity: 70%;
}

/* Style for the background image with overlay */
.background-overlay {
  padding-left: 0;
  margin-left: 0;
  width: 110%;
  background-image: url('../assets/overlay.jpeg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  flex-direction: row; /* Horizontal layout */
  border-radius: 0; /* Rounded corners */
  overflow: hidden; /* Hide overflow content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  color: #259444;
}

.solid-blue-section {
  flex: 5; /* Takes up 1/3 of the width */
  background: linear-gradient(to right, rgba(46, 194, 242, 1), rgba(46, 194, 242, 0.9));
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solid-blue-section h1 {
  font-size: xxx-large;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow */
  max-width: 90%;
  padding-left: 5%;

}

.semi-transparent-section {
  flex: 12; /* Takes up 2/3 of the width */
  background: linear-gradient(to right, rgba(46, 194, 242, 0.9), rgba(46, 194, 242, 0.4));
  color: #333; /* Darker text color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.semi-transparent-section p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-large;
  text-align: justify;
  max-width: 90%;
  color: white;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4); /* Text shadow */
  margin-right: 10%;

}

.solid-green-section {
  flex: 5;
  background: linear-gradient(to right, rgba(136, 200, 51, 1), rgba(136, 200, 51, 0.9));
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}

.solid-green-section h1 {
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  padding-left: 5%;
  margin: 0;
}

.semi-transparent-green-section {
  flex: 12;
  background: linear-gradient(to right, rgba(136, 200, 51, 0.9), rgba(136, 200, 51, 0.4));
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
}


</style>

