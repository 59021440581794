<template>
  <div class="main-content">
    <!-- <v-container class="fill-height" fluid style="padding: 0"> -->
    <v-row class="fill-height">
      <v-col justify="center" class="d-flex justify-center">
        <v-row align="center" justify="center">
          <v-img height="300" contain src="../assets/logo.png" /></v-row>
      </v-col>
      <v-col class="right" v-if="page == 1">
        <v-container class="fill-height">
          <v-col style="padding: 200px">
            <v-row><v-expand-transition>
              <v-alert v-if="error" type="error" dismissible transition="scale-transition" elevation="1" class="mb-4">
                {{ error }}
              </v-alert>
              </v-expand-transition>
            </v-row>
            <v-row>
              <v-text-field v-model="email" :readonly="loading" :rules="[required]" class="mb-2 text-white" clearable @keydown.enter="login"
                label="Email"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field class="text-white" v-model="password" :readonly="loading" :rules="[required]" clearable type="password" @keydown.enter="login"
                label="Contraseña"></v-text-field>
            </v-row>
            <v-row>
              <v-btn :loading="loading" block class="mt-2" size="large" type="submit" variant="elevated" @click="login">
                INICIAR SESIÓN
              </v-btn>
            </v-row>
            <v-row>
              <v-btn :loading="loadingPass" block class="mt-2 text-white" variant="text" @click="goToPassView">
                ¿Olvidaste tu contraseña? Haga click aquí
              </v-btn>
            </v-row>
          </v-col>
        </v-container>
      </v-col>
      <v-col class="right" v-if="page == 2">
        <v-container class="fill-height">
          <v-col style="padding: 200px">
            <v-row>
              <v-expand-transition>
              <v-alert v-if="error" type="error" dismissible transition="scale-transition" elevation="1" class="mb-4">
                {{ error }}
              </v-alert>
              </v-expand-transition>
            </v-row>
            <v-row>
              <v-text-field v-model="forgottenPassEmail" :readonly="loading" :rules="[required]" class="mb-2" clearable
                label="Email" type="email"></v-text-field>
            </v-row>
            <v-row>
              <v-btn :loading="loading" block class="mt-2" size="large" type="submit" variant="elevated"
                @click="goToRecoverPasswordView" :disabled="required(forgottenPassEmail) == 'Campo requerido'">
                CONTINUAR
              </v-btn>
            </v-row>
            <v-row>
              <v-btn :loading="loadingBack" block class="mt-2" size="large" type="submit" variant="elevated"
                @click="goToLoginView">
                VOLVER AL INICIO DE SESIÓN
              </v-btn>
            </v-row>
          </v-col>
        </v-container>
      </v-col>
      <v-col class="right" v-if="page == 3">
        <v-container class="fill-height">
          <v-col style="padding: 200px">
            <v-row><v-expand-transition>
              <v-alert v-if="error" type="error" dismissible transition="scale-transition" elevation="1" class="mb-4">
                {{ error }}
              </v-alert></v-expand-transition>
            </v-row>
            <v-row>
              <v-alert color="info" icon="$info" title="Correo electrónico enviado"
                text="Se ha enviado un correo electrónico a la dirección indicada con el token para recuperar su contraseña."
                dismissible transition="scale-transition" elevation="1" class="mb-4">
              </v-alert>
            </v-row>


            <v-row>
              <v-text-field v-model="token" :readonly="loading" :rules="[required]" class="mb-2" clearable
                label="Token"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field v-model="newPass" :readonly="loading" :rules="[required]" class="mb-2" clearable
                label="Nueva Contraseña" type="password"></v-text-field>
            </v-row>
            <v-row>
              <v-btn :loading="loading" block class="mt-2" size="large" type="submit" variant="elevated"
                @click="recoverPass">
                CAMBIAR CONTRASEÑA
              </v-btn>
            </v-row>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    <!-- <v-footer style="background-color: #0f0a31" app height="40">
      <v-row style="color: white; font-size: small">
        Copyright {{ new Date().getFullYear() }}
      </v-row>
    </v-footer> -->
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "LoginComponent",
  data() {
    return {
      error: '',
      email: "",
      password: "",
      forgottenPassEmail: "",
      token: "",
      newPass: "",
      loading: false,
      loadingPass: false,
      loadingBack: false,
      page: 1,
    };
  },
  methods: {
    required(value) {
      return !!value || "Campo requerido";
    },
    async login() {
      this.loading = true;
      const email = this.email;
      const password = this.password;


      try {
        var data_post = {
          username: email,
          password: password,
        };
        const response = await axios.post('https://localhost:44343/login', data_post);

        // Verifica si la autenticación fue exitosa y obtén el rol

        var data = response.data.user
        if (data && data.id  && data.rol){
          this.$store.commit('setUserEmail', email);
          this.$store.commit('setUserPassword', password);
          this.$router.push({ name: 'home' });
        } else {
          this.error = 'Usuario o contraseña incorrectos. Por favor, vuelvelo a intentar.';
        }
      } catch (error) {
        // Manejo de errores, por ejemplo, mostrar un mensaje de error en caso de error de red
        console.error('Error de autenticación:', error);
        this.error = 'Se produjo un error al intentar autenticarse. Por favor, nténtelo de nuevo más tarde.';
      }

      this.loading = false;
    },

    
    goBack(){
        this.error = '';
        this.loadingBack = true;
        this.page = 2;
        this.loadingBack = false;
    },
    goToLoginView(){
        this.error = '';
        this.loading = true;
        this.page = 1;
        this.loading = false;
    },
    goToPassView(){
        this.error = '';
        this.loadingPass = true;
        this.page = 2;
        this.loadingPass = false;
    }
  },


};
</script>


<style scoped>
.main-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.main-row {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.left {
  background-color: white;
}

.right {
  background-color: #5BD1D7;
}

.text-darkgrey input {
      color: darkgrey !important;
    }
</style>