<template>
  <v-app>
  <v-container class="fadein">
    <v-row cursor= "default" no-gutters align="top" justify="start" style="cursor: default; margin-top: 3%;">
      <v-col cols=12 md=7 xs=12 sm=12 lg=7 align="start">
        <v-row style="height: auto;" justify="center">
          <v-col name="nombre-servicio" cols=12 md=10 xs=10 sm=10 lg=10 class="d-flex">
            <h1 style="white-space: wrap;">LIMPIEZA PROFESIONAL PROFUNDA</h1>
          </v-col>
        </v-row>
        <v-row align-content="center" justify="end" style="padding-top: 2%;">
          <v-col cols=12 md=11 xs=11 sm=11 lg=11 align="left" justify="center">
            <p style="font-size: 1.1vw; margin-bottom: 3%;">
              ¿Tiene la sensación de que no hay suficiente tiempo para limpiar su hogar? ¿Está cansado de limpiezas de rutina que no le dan los resultados que desea? Si es así, ¡entonces nuestro servicio de limpieza profesional profunda es para usted!            </p>  
            <p style="font-size: 1.1vw; margin-bottom: 3%;">
              Nuestros profesionales cualificados realizarán una limpieza profunda, extensa y completa de su hogar. Limpiamos todos los rincones y esquinas, desde los suelos y techos hasta los muebles y los electrodomésticos. Utilizaremos los mejores productos y equipos para garantizar una limpieza profunda y efectiva.            </p>  
            <p style="font-size: 1.1vw; margin-bottom: 3%;">
              Además, eliminaremos cualquier tipo de suciedad, polvo, moho y grasa acumulada, así como cualquier otro tipo de mancha. Utilizaremos técnicas profesionales para garantizar que su hogar esté completamente limpio.            </p>  
            <p style="font-size: 1.1vw; margin-bottom: 3%;">
              Nuestro servicio de limpieza profunda está diseñado para ahorrarle tiempo y esfuerzo. Si desea un lugar limpio y reluciente, ¡no busque más!
            </p>
            <p style="font-size: 1.1vw; margin-bottom: 3%;">
              ¡Póngase en contacto con nosotros para recibir un presupuesto personalizado sin compromiso!
            </p>
          </v-col>
        </v-row>
      </v-col>        
      <v-col cols=12 md=5 xs=12 sm=12 lg=5 align="center">
        <v-img src='../assets/aspirando.png' class="text-img" />
      </v-col>
    </v-row >
    <contacto></contacto>

</v-container>
</v-app>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import Contacto from './Partials/Contacto.vue';

export default defineComponent({
  components:{
    Contacto,
  },
  name: 'HomeComponent',

  //mounted() {<div class='sk-ww-google-reviews' data-embed-id='189108'></div> 
    // Load the external script when the component is mounted
    //const script = document.createElement('script');
    //script.src = 'https://widgets.sociablekit.com/google-reviews/widget.js';
    //script.async = true;
    //script.defer = true;
    //document.head.appendChild(script);
  //},
  data () {
    return {
      showModal: false,
      name:'',
      email:'',
      phone:'',
      message:'',
      servicio:'',
      servicios: [
        {nombreServicio:'Limpieza',descripcion:'Servicio de limpieza', src:require('../assets/serviciosLimpieza.png')},
        {nombreServicio:'Pintura',descripcion:'Servicio de Pintura', src:require('../assets/serviciosPintura.png')}],
    }
  },
  methods: {
    async submit(servicio: string,name: string,email: string,phone: string,message: string) {
      var data = {
        servicio: servicio,
        name: name,
        email: email,
        phone: phone,
        message: message,
      };

      /*  LLamada a la api para pedir los datos de la tabla */
      await fetch('https://www.sarabiaserviciosintegrales.es/form_mail', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          console.log(result);
          this.name='';
          this.email='';
          this.phone='';
          this.message='';
          this.servicio='';
          alert("Formulario enviado.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },

})
</script>


<!-- 
  STYLE
 -->
<style>
body {
  background: #effbff;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
.text-img{
  width: 95% !important;
  /* -webkit-animation: fadein 1.2s ease-in alternate ;
  -moz-animation: fadein 1.2s ease-in alternate ;
  animation: fadein 1.2s ease-in alternate ; */
  cursor: default;
  box-shadow:none;
  align-self: center;
  /* transition: transform 0.2s ease-in-out; */
}
.fadein{
  -webkit-animation: fadein 1.2s ease-in alternate ;
  -moz-animation: fadein 1.2s ease-in alternate ;
  animation: fadein 1.2s ease-in alternate ;
  transition: transform 0.2s ease-in-out;
}


/*.text-img:active{
  transform: scale(3);
  z-index: 50;
  vertical-align: center;
  width: 20% !important;
  height: 50% !important;
  -moz-box-shadow: 0 0 1050px 20px #000;
  -webkit-box-shadow: 0 0 1050px 20px #000;
  box-shadow: 0 0 1050px 20px #000; 
}*/
.primer-texto h1 {
  text-align: center;
}

.primer-texto p{
  margin: 10px ;
  text-align: justify !important;
}
.text-img{
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: auto;
}

.sk_reviews_grid-item {
  display: none;
}

.sk_reviews_grid-item sk_reviews_badge_container{
  display: contents;
}

.v-card.form{
  border: 1px solid #8ed43e !important;
  width: 80% !important; 
  height: 50% !important;
}
.v-form .v-field__field {
  background-color:white !important;
  font-weight: bold;
/*  opacity: 0.2;
*/
}
.tf label{
  color:  #259444 !important;
}
.contact-form {
  background-color: #88c641;
  font-family: 16px !important;
  margin: 0 auto;
  padding: 30px;
  max-width: 50% ;
  
}

.contact-form .separator {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.separator {
  border-bottom: dotted 1px #ccc;
  margin-bottom: 15px;
}

.contact-form .form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form_title {
  color: black;
  font-weight: bold;
  font-size: 28px !important;
}

.contact-form select
.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 10px 7px;
  margin-bottom: 15px;
  outline: none;
}
select {  
  /* styling */
  background-color: white;
  border: none;
  border-radius: 4px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  border-radius: 3px;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  color: #259444 !important;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
  linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%),
  linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  width: 100%;
}

select:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select label{
  color:  #259444 !important;
}
.contact-form textarea {
  resize: none;
}

.contact-form .button {
  background: #2fc1f2;
  border: solid 1px #da552f;
  color: white;
  cursor: pointer;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
}

.contact-form .button:hover {
  background: #00a2ff;
  border: solid 1px #ea532a;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form textarea,
.contact-form .button {
  font-size: 15px;
  border-radius: 3px;
}
.row__services{
  padding-top: 10vh;
}
#col__servicesPainting{
  padding-top: 4.6vh;
}
@media only screen and (min-width: 1200px) {

#col__servicesPainting{
  padding-top: 4.6vh;
  padding-right: 20vw;
}
#col__servicesCleaning{
  padding-left: 20vw;
}
}

.glowable{
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s; 
}
.glowable:hover {
  opacity: 0.8;
  transform: scale(1.10);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.04);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.10);
    opacity: 1;
  }
}
</style>

